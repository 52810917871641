Vue.component('paginator', {
    props: {
        current_page: Number,
        total_page: Number,
    },
    data: function () {
        return {
            current_page: this.current_page
        }
    },
    template: "<pagination v-on:change=\"$emit('reload',current_page )\" v-model='current_page' :total-page='total_page'/>"
    //template: '<h3>{{ current_page }}</h3>'
});